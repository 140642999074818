
export default {
  prefix: 'fas',
  iconName: 'scroll-down',
  icon: [
    107,
    209,
    [],
    'f0000',
    `M53.5,62c-3.3,0-6-2.7-6-6V39c0-3.3,2.7-6,6-6s6,2.7,6,6v17C59.5,59.3,56.8,62,53.5,62z M106,108.5v-55
	C106,24.6,82.4,1,53.5,1C24.6,1,1,24.6,1,53.5v55C1,137.4,24.6,161,53.5,161C82.4,161,106,137.4,106,108.5z M94,53.5v55
	c0,22.3-18.2,40.5-40.5,40.5C31.2,149,13,130.8,13,108.5v-55C13,31.2,31.2,13,53.5,13C75.8,13,94,31.2,94,53.5z M57.7,206.7
	l16.5-16.5c2.3-2.3,2.3-6.1,0-8.5c-2.3-2.3-6.1-2.3-8.5,0L53.5,194l-12.3-12.3c-2.3-2.3-6.1-2.3-8.5,0c-2.3,2.3-2.3,6.1,0,8.5
	l16.5,16.5c1.2,1.2,2.7,1.8,4.2,1.8C55,208.5,56.6,207.9,57.7,206.7z`,
  ],
}