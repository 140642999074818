export { GlobalStyle } from './globalStyle'

const colors = {
  primary: ['#312C29', '#533F32', '#604C39'],
  secondary: ['#ECD1BA', '#FFE9D0', '#FFF6EC', '#fdf8f2'],
  contrastSecondary: '#826f5c', // AA contrast compliant on white
  contrastPrimary: '#b09174', // AA contrast compliant on primary.1
  blue: '#087ed8',
  tomato: '#FF856A',
  lightgrey: '#b7b3af',
  lightgreyAA: '#7c746c',
  lightergrey: '#f4f1ed',
  white: '#fff',
  black: '#222',
}
const breakpoints = ['320px', '500px', '700px', '950px', '1300px', '1921px']

// aliases
breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]
breakpoints.xxl = breakpoints[5]

export default {
  container: 96, // em = 1200px - body font size = 12.5px, 96 * 12.5 = 1200
  breakpoints: breakpoints,
  fontSizes: { 0: 14, 1: 16, 2: 18, 3: 22, 4: 24, 5: 32, 6: 48, 7: 64, xs: 11 },
  lineHeights: [1, 1.2, 1.3, 1.33, 1.6, 1.8],
  colors,
  space: {
    0: 0,
    1: 12.5,
    2: 25,
    3: 50,
    4: 75,
    5: 100,
    6: 125,
    7: 150,
    // bad idea to use an array it turns out...
    // rather do this than bump every single usage of space by 1 numberal though.
    xs: 6.25,
    navBarHeight: 75,
  },
  sizes: {
    navBarHeight: '75px',
    articleWidth: '600px',
  },
  fonts: {
    sans: 'proxima-nova, system-ui, helvetica, arial, sans-serif',
    serif: 'utopia-std-subhead, Georgia, Times, serif',
    mono: 'Menlo, monospace',
  },
  radii: ['3px', '7.5px', '12.5px', '25px', '9999px'],
  shadows: {
    small: '0 0 9px rgba(0, 0, 0, .150)',
    medium: '4px 4px 16px rgba(0, 0, 0, .175)',
    large: '4px 4px 24px rgba(0, 0, 0, .125)',
    page: '0px 12.5px 34px rgba(0, 0, 0, .065)',
  },
  buttons: {
    primary: {
      color: colors.white,
      backgroundColor: colors.primary[1],
      '&:hover': {
        backgroundColor: colors.secondary[1],
        color: colors.primary[1],
      },
    },
    secondary: {
      color: colors.primary[1],
      backgroundColor: colors.secondary[1],
      '&:hover': {
        backgroundColor: colors.primary[1],
        color: colors.white,
      },
    },
    outline: {
      color: colors.primary[1],
      backgroundColor: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
      '&:hover': {
        backgroundColor: colors.primary[1],
        color: colors.secondary[1],
        boxShadow: `inset 0 0 0 2px ${colors.primary[1]}`,
      },
      '&:focus': {
        boxShadow: `inset 0 0 0 2px ${colors.contrastPrimary}`,
      },
    },
    outlineReverse: {
      color: colors.primary[1],
      backgroundColor: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
      '&:hover': {
        backgroundColor: colors.primary[1],
        color: colors.secondary[1],
        boxShadow: `inset 0 0 0 2px ${colors.primary[1]}`,
      },
    },
    outlineReverseSecondary: {
      color: colors.secondary[1],
      backgroundColor: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
      '&:hover': {
        backgroundColor: colors.secondary[1],
        color: colors.primary[1],
        boxShadow: `inset 0 0 0 2px ${colors.secondary[1]}`,
      },
    },
    ghost: {
      backgroundColor: 'transparent',
    },
    blank: {},
  },
}
