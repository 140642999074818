import React from 'react'
import { ThemeProvider } from 'styled-components'
import theme, { GlobalStyle } from './src/theme'
import './src/theme/icons'

// export const shouldUpdateScroll = () => {
//     try {
//       window.scroll({
//         top: 0,
//         left: 0,
//         behavior: 'smooth',
//       })
//     } catch (error) {
//       window.scrollTo(0, 0)
//     }
// }
export const wrapPageElement = ({ element }) => {
  return (
    <>
      <GlobalStyle theme={theme} />
      <ThemeProvider theme={theme}>{element}</ThemeProvider>
    </>
  )
}
