import { library } from '@fortawesome/fontawesome-svg-core'

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'

import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import { faCoffee } from '@fortawesome/free-solid-svg-icons/faCoffee'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faGuitar } from '@fortawesome/free-solid-svg-icons/faGuitar'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle'

import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter'
import { faAlgolia } from '@fortawesome/free-brands-svg-icons/faAlgolia'

import { faScrollDown } from '../icons'

config.autoAddCss = false

library.add(
  faCheckSquare,
  faCoffee,
  faBars,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faExpand,
  faEnvelope,
  faFacebook,
  faFacebookF,
  faTwitter,
  faGuitar,
  faAlgolia,
  faSearch,
  faScrollDown,
  faRedoAlt,
  faPlayCircle,
  faSpinner
)
