// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-instrument-post-index-js": () => import("./../src/templates/instrumentPost/index.js" /* webpackChunkName: "component---src-templates-instrument-post-index-js" */),
  "component---src-templates-instruments-index-js": () => import("./../src/templates/instruments/index.js" /* webpackChunkName: "component---src-templates-instruments-index-js" */),
  "component---src-templates-single-product-index-js": () => import("./../src/templates/singleProduct/index.js" /* webpackChunkName: "component---src-templates-single-product-index-js" */),
  "component---src-templates-shop-index-js": () => import("./../src/templates/shop/index.js" /* webpackChunkName: "component---src-templates-shop-index-js" */),
  "component---src-templates-generic-page-js": () => import("./../src/templates/GenericPage.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-news-index-js": () => import("./../src/templates/news/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-news-by-category-index-js": () => import("./../src/templates/news/byCategory/index.js" /* webpackChunkName: "component---src-templates-news-by-category-index-js" */),
  "component---src-templates-news-post-index-js": () => import("./../src/templates/newsPost/index.js" /* webpackChunkName: "component---src-templates-news-post-index-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

