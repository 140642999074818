import { createGlobalStyle } from 'styled-components'
export const GlobalStyle = createGlobalStyle`

  * {
    box-sizing: border-box;
  }

  html {
    /* safari why you broken?  */
    height: 100%;
  }
  body {
    height: 100%;
    margin: 0;
    font-family: ${props => props.theme.fonts['serif']};
    font-size: 12.5px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    color: black;
  }
  a {
    display: inline-block;
    color:  ${props => props.theme.colors['tomato']};
    text-decoration: none;
  }
  button {
    font-family: ${props => props.theme.fonts['sans']};
  }
  ::selection {
    background-color: ${props => props.theme.colors.secondary[0]};
    /* color: ${props => props.theme.colors.dark}; */
  }
  ::-moz-selection {
    background-color: ${props => props.theme.colors.secondary[0]};
    color: white;
  }

  a:focus, button:focus {
    outline: 1px solid ${props => props.theme.colors.tomato};
    outline-offset: -1px;
    outline: none;
    box-shadow: 0px 0px 0px 2px ${props => props.theme.colors.tomato};
              &.inset-focus {
            box-shadow: inset 0px 0px 0px 2px ${props =>
              props.theme.colors.tomato};
      }
  }
  .has-dark-background a:focus, .has-dark-background button:focus {
    outline: 1px solid ${props => props.theme.colors.contrastPrimary};
    outline-offset: -1px;
    outline: none;
    box-shadow: 0px 0px 0px 2px ${props => props.theme.colors.contrastPrimary};
        &.inset-focus {
            box-shadow: inset 0px 0px 0px 2px ${props =>
              props.theme.colors.contrastPrimary};
      }
  }
.inset-focus:focus {
  outline: none;
            box-shadow: inset 0px 0px 0px 2px ${props =>
              props.theme.colors.contrastPrimary};
      }
  h1 {
    font-size: 3.5em;
  }
  h2 {
    font-size: 2.5em;

  }
  h3 {
    font-size: 1.75em;
  }
  h4 {
    font-size: 1.5em;
  }
  h5 {
    font-size: 1em;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  /* fix some issues I was having with
   gatsby-plugin-transition-link's wrapper being relative */
  .tl-wrapper {
    float: unset;
    position: absolute;
  }
  .tl-edges {
    overflow: visible;
  }
`
